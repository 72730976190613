import React from "react";
import HomeBg from "../images/homebg.jpg";

function LegalPagesHead({ title }: { title: string }) {
  return (
    <section
      className="homeSection home_ptb"
      style={{ backgroundImage: `url(${HomeBg})`, height: "250px" }}
    >
      <div className="bannerOverlay "></div>
      <div className="homeBannerContent_box px-5 mt-5">
        <h1 className="title f-5">
          {title.split(" ").map((i, index) => (
            <React.Fragment key={index}>
              {index % 2 === 0 ? i : <span className="primary-text">{i}</span>}{" "}
            </React.Fragment>
          ))}
        </h1>
      </div>
    </section>
  );
}

export default LegalPagesHead;
