import React, { useEffect, useState } from "react";
import LegalPagesHead from "../../component/LegalPagesHead";
import SuccessGif from "../../component/images/success.gif";
import { t } from "i18next";
import { getTransactionStatusService } from "../../services/user";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, useLanguage } from "../../app/hooks";
import {
  getUserPaymentMethodsThunk,
  getUserThunk,
} from "../../redux/thunks/user";
import SHA256 from "crypto-js/sha256";

const TransactionSuccess = () => {
  const [redirectUrl, setRedirectUrl] = useState("");
  const navigate = useNavigate();
  const [time, setTime] = useState("");
  const { isLoggedIn, user } = useAppSelector((state) => state.auth);
  const [resendDisabled, setResendDisabled] = useState(true);
  

  useEffect(() => {
    checkTransactionStatus();
  }, []);
  const checkTransactionStatus = async () => {
    try {
      const searchQuery = new URLSearchParams(window.location.search);
      const ref = searchQuery.get("ref");
      if (ref) {
        const res = await getTransactionStatusService(ref);
        if (res.data.status) {
          // toast.success(t("pages.modals.rentSuccessMsg"));
          const { details } = res.data;

          // Assuming `user` contains the user's data
          const email = String(user?.email || "").trim().toLowerCase();
          const phone = user?.phone || "";

          // Hash the email and phone
          const hashedEmail = SHA256(email).toString();
          const hashedPhone = SHA256(phone).toString();

          // Assuming `movie.rentPrice` contains the rent value and `currency` is globally defined
          const currency = "SAR"; // Replace with dynamic currency if applicable
          const value_of_order = res.data.sarRentPrice; // Value of the order
          const rent_value = res.data.sarRentPrice; // Rental value

          // Push to dataLayer
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
              event: "checkoutComplete",
              customer: {
                  email: email,
                  phone: phone,
                  email_hashed: hashedEmail,
                  phone_hashed: hashedPhone,
                  currency: currency,
                  value_for_order: value_of_order,
                  rent_value: rent_value,
              },
          });
          // setRedirectUrl(details);
        }
      }
      setRedirectUrl('/');
    } catch (error) {
      console.log(error);
    }
  };
  function startTimer(duration = 10) {
    var timer = duration,
      minutes: string | number,
      seconds;
    let int = setInterval(function () {
      minutes = parseInt(String(timer / 60), 10);
      seconds = parseInt(String(timer % 60), 10);

      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;

      setTime(String(seconds));
      if (+minutes === 0 && +seconds === 0) {
        // navigate(redirectUrl, {
        //   replace: true,
        // });
      }
      if (--timer < 0) {
        timer = duration;
        clearInterval(int);
      }
    }, 1000);
  }

  // useEffect(() => {
  //   startTimer();
  // }, []);

  return (
    <div className="text-center">
      <LegalPagesHead title={t("thankYou")} />
      <div className="homeBannerContent_box px-5 mt-5">
        <div>
          <iframe src="https://lottie.host/embed/9b3bc142-5419-4d06-b6a9-d92938a6de67/HKjNKGLAaQ.json"></iframe>
        </div>
        <h1 className="mb-5 f-5">
          {t("paymentSuccess")
            .split(" ")
            .map((i, index) => (
              <React.Fragment key={index}> {/* Use React.Fragment with a key */}
              {index % 2 === 0 ? (
                <span className="text-white">
                  {" "}
                  {i}
                </span>
              ) : (
                <span className="primary-text">
                  {" "}
                  {i}
                </span>
              )}
            </React.Fragment>
            ))}
        </h1>
        <div className="my-5">
          {/* <span className="text-white fs-4">
            {t("dontRefresh", {
              time: time,
            })}
          </span> */}
          <Button
            onClick={() =>
              navigate(redirectUrl, {
                replace: true,
              })
            }
            className="cBtn fs-4 px-5"
            variant="primary"
          >
            {t("proceed")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TransactionSuccess;
